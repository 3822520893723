import styled from "./styled-components";
import { COLOR } from "./constants";

export const StyledContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: ${COLOR.WHITE};
  position: relative;
  overflow: none;
`;

export const AboveTheFold = styled.div`
  position: relative;
  box-shadow: 0 0.8rem 1rem 0 rgba(${COLOR.RICHBLACK_RBG}, 0.15);
  z-index: 10;
`;

export const BrowserWindow = styled.div`
  position: relative;
  z-index: 8;
`;

export const Story = styled.div`
  position: relative;
  background-color: ${COLOR.VDEP_SECONDARY_BLUE};
  z-index: 2;
  padding: 4rem 2rem 4rem;
  display: flex;
  flex-direction: column;

  color: ${COLOR.VDEP_SECONDARY_BLUE};

  h2 {
    font-family: "Roboto Slab", "sans-serif";
    text-transform: uppercase;
    font-size: 2.2rem;
    font-weight: bold;
    margin: 0 0 3rem 0;
    text-align: center;
    color: ${COLOR.VDEP_GREEN};
  }
  p,
  ol,
  li {
    color: ${COLOR.VDEP_TERTIARY_BLUE};
    font-family: "Roboto Slab", "sans-serif";
    font-weight: 300;
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
`;

export const StoryBody = styled.div`
  margin: 2rem auto 0.2rem;
  max-width: 80%;
  display: flex;
  flex-direction: column;

  h2 {
    margin-top: 4rem;
  }
  strong {
    margin-top: 2rem;
  }
`;

export const OrganisationCTA = styled.div<{ imgUrl: string }>`
  position: relative;
  // height: 13rem;
  background-image: url(${props => props.imgUrl});
  background-size: cover;
  background-position: center;
  text-align: center;
  padding: 4rem;

  h3 {
    font-family: "Roboto", "sans-serif";
    color: ${COLOR.VDEP_BLUE};
    font-size: 2rem;
  }
  h2 {
    font-family: "Roboto Slab", "sans-serif";
    color: ${COLOR.VDEP_TERTIARY_BLUE};
    font-size: 2.2rem;
  }
`;

export const MissionStatementContainer = styled.div`
  position: relative;
  background-color: ${COLOR.WHITE};
  z-index: 2;
  text-align: center;
  padding: 4rem 2rem 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const MissionStatement = styled.div`
  margin: auto;
  max-width: 90rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const MissionText = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  h1,
  h2 {
    text-align: center;
    color: ${COLOR.VDEP_SECONDARY_BLUE};
    font-family: "Roboto Slab", "sans-serif";
    letter-spacing: 0.15em;
    text-transform: uppercase;
    font-size: 2.2rem;
    font-weight: bold;
    margin: 0 0 3rem 0;
  }

  p {
    text-align: justify;
    color: ${COLOR.VDEP_SECONDARY_BLUE};
    font-family: "Roboto", "sans-serif";
    font-size: 1.6rem;
    line-height: 2.2rem;
    font-weight: 500;
    margin: 1.5rem 1.5rem;

    strong {
      font-weight: 700;
    }
  }
`;

export const MissionImageContainer = styled.div`
  img {
    width: 40rem;
    height: 30rem;
    object-fit: cover;
  }
`;

export const AboutImageContainer = styled.div`
  img {
    width: 30rem;
    height: 40rem;
    object-fit: cover;
  }
`;

export const Expertise = styled.div`
  position: relative;
  background-color: ${COLOR.WHITE};
  z-index: 2;
  text-align: center;
  padding: 4rem 2rem 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > div {
    margin: auto;
    text-align: center;
    max-width: 90rem;
  }

  h2 {
    text-align: center;
    color: ${COLOR.VDEP_SECONDARY_BLUE};
    font-family: "Roboto Slab", "sans-serif";
    letter-spacing: 0.15em;
    text-transform: uppercase;
    font-size: 2.2rem;
    font-weight: bold;
    margin: 0 0 3rem 0;
  }

  h4 {
    text-align: center;
    color: ${COLOR.VDEP_SECONDARY_BLUE};
    font-family: "Roboto", "sans-serif";
    font-size: 1.8rem;
    font-weight: bold;
    margin: 0 0 3rem 0;
  }
  p {
    text-align: justify;
    color: ${COLOR.VDEP_SECONDARY_BLUE};
    font-family: "Roboto", "sans-serif";
    font-size: 1.6rem;
    line-height: 2.2rem;
    font-weight: 500;
    margin: 1.5rem auto;

    strong {
      font-weight: 700;
    }
  }
`;

export const FunctieChips = styled.div`
  margin-top: 6rem;
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5rem;
  width: 100%;
  z-index: 100;
  text-align: center;

  a {
    text-decoration: underline;
  }
`;
